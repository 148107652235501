import { Link, useLocation } from 'react-router-dom';
import { DashboardOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import smallIcon from '../../../assets/images/logo.png';
import './SideMenu.less';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/auth/AuthContext';
import { FormattedMessage, useIntl } from 'react-intl';
const { Sider } = Layout;
const { SubMenu } = Menu;

interface Props {
    collapsed: boolean;
    isMobile: boolean;
}

const SideMenu = ({ collapsed, isMobile }: Props) => {
    const location = useLocation();
    const intl = useIntl();
    const icons = require(`@ant-design/icons`);
    const [selectedKey, setSelectedKey] = useState<string>(location.pathname);
    const [subMenuKey, setSubMenuKey] = useState<string>();
    const { authState } = useAuth();
    const [sideNavs, setSideNavs] = useState<any[]>();

    useEffect(() => {
        const selectedKeys = location.pathname.split('/');
        setSelectedKey(location.pathname);
        const expandedSubMenu = selectedKeys.length > 2 ? `/${selectedKeys[1]}` : undefined;
        setSubMenuKey(collapsed ? undefined : expandedSubMenu);
    }, [location.pathname, collapsed]);

    useEffect(() => {
        if (authState.sideNav && authState.sideNav.length > 0) {
            const sortedData = authState.sideNav.sort((a: any, b: any) => handleSort(a, b));
            const parentSideNavs: any[] = sortedData.filter(sn => !sn.parentId);

            const nestedSideNav: any[] = parentSideNavs.map(sn => {
                const parentSideNav = { ...sn };
                parentSideNav.child = sortedData.filter(c => c.parentId === sn.id);
                return parentSideNav;
            });
            setSideNavs(nestedSideNav);
        }
    }, [authState.sideNav])

    const handleSort = (a: any, b: any): number => {
        if (a.order > b.order) return 1;
        else if (a.order < b.order) return -1;
        return 0;
    }

    const handleSubMenuClick = (e: any) => {
        if (!e || (e && e.length === 0)) {
            setSubMenuKey(undefined);
        } else if (e && e.length > 1) {
            setSubMenuKey(e[1]);
        } else if (e && e.length > 0) {
            setSubMenuKey(e[0]);
        }
    }

    const getIcon = (iconName: string): any => {
        const AntdIcon = icons[iconName.trim()];
        return AntdIcon ? <AntdIcon /> : null;
    }

    const renderMenu = () => {
        return (
            <Menu theme="dark" mode="inline" triggerSubMenuAction="click" onOpenChange={handleSubMenuClick}
                selectedKeys={[selectedKey ? selectedKey : '/']} openKeys={subMenuKey ? [subMenuKey] : undefined}>
                <Menu.Item key="/" icon={<DashboardOutlined />}>
                    <Link to="/">{intl.formatMessage({ id: "DASHBOARD" })}</Link>
                </Menu.Item>
                {
                    sideNavs?.map((sideNav) => {
                        return (sideNav.child ?
                            <SubMenu key={sideNav.route} icon={getIcon(sideNav.icon)} title={intl.formatMessage({ id: sideNav.name })}>
                                {renderChildMenu(sideNav.child)}
                            </SubMenu > :
                            <Menu.Item key={sideNav.route} icon={getIcon(sideNav.icon)} >
                                <Link to={sideNav.route}>{intl.formatMessage({ id: sideNav.name })}</Link>
                            </Menu.Item>);
                    })
                }
            </Menu>);
    }

    const renderChildMenu = (childMenu: any[]) => {
        return childMenu.map((child) => {
            return (<Menu.Item key={child.route} >
                <Link to={child.route}>{intl.formatMessage({ id: child.name })}</Link>
            </Menu.Item>);
        })
    }

    return (
        <Sider trigger={null} collapsible collapsed={collapsed} collapsedWidth={isMobile ? 0 : 64} width={256}>
            <div className={!collapsed ? "side-nav-logo" : "side-nav-logo collapsed"}>
                <img src={smallIcon} alt="small-icon" />
                <span className="logo-name"><FormattedMessage id="PORTAL_NAME" /></span>
            </div>
            {renderMenu()}
        </Sider>
    )
}

export default SideMenu;