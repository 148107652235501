import React from 'react';
import { Dropdown, Layout, Menu } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    LogoutOutlined,
    LockOutlined
} from '@ant-design/icons';
import { Link } from "react-router-dom";
import './GeneralHeader.less';
import { useAuth } from '../../../hooks/auth/AuthContext';
import LanguageSwitcher from '../../widgets/language-switcher/LanguageSwitcher';
import { useIntl } from 'react-intl';
const { Header } = Layout;
interface Props {
    collapsed: boolean;
    toggleSideNav?: () => void;
    logout?: () => void;
}

const GeneralHeader = ({ collapsed, toggleSideNav, logout }: Props) => {
    const { authState } = useAuth();
    const intl = useIntl();
    const name = authState.userProfile && authState.userProfile?.name ?
        authState.userProfile?.name : '';


    const menu = (
        <Menu>
            <Menu.Item key="0" icon={<LockOutlined />}>
                <Link to="/settings/change-password">{intl.formatMessage({ id: "CHANGE_PASSWORD" })}</Link>
            </Menu.Item>
            <Menu.Item key="1" icon={<LogoutOutlined />} onClick={logout}>
                {intl.formatMessage({ id: "LOGOUT" })}
            </Menu.Item>
        </Menu>
    );

    return (
        <Header className="site-layout-header" style={{ padding: 0 }}>
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: toggleSideNav,
            })}
            <div>
                <LanguageSwitcher />
                <Dropdown overlay={menu} className="profile-menu" trigger={['hover', 'click']}>
                    <a className="ant-dropdown-link" href="/#" >  <UserOutlined /><span className="profile-menu-title">{name}</span> </a>
                </Dropdown>
            </div>
        </Header>
    )
}

export default GeneralHeader;