import { FormattedMessage } from 'react-intl';
import './Dashboard.less';


const Dashboard = () => {
  
  return (
    <>
      <FormattedMessage id="PORTAL_NAME" /> 
    </>
  );
}

export default Dashboard;