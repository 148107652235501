import React from "react";
import NoMatch from "./NoMatch";

export interface RouteInfo {
    path: string,
    component: any,
    routes?: RouteInfo[],
    lazy?: boolean,
    protected?: boolean
}

const ReactLazyPreload = (importStatement: any) => {
    const Component: any = React.lazy(importStatement);
    Component.preload = importStatement;
    return Component;
};

//preload pages
const SideNavigationForm = ReactLazyPreload(() => import('./pages/side-navigation/SideNavigationForm'));
SideNavigationForm.preload();
const SideNavigation = ReactLazyPreload(() => import('./pages/side-navigation/SideNavigation'));
SideNavigation.preload();
const SystemUserForm = ReactLazyPreload(() => import('./pages/system-user/SystemUserForm'));
SystemUserForm.preload();
const SystemUser = ReactLazyPreload(() => import('./pages/system-user/SystemUser'));
SystemUser.preload();
const SystemConfiguration = ReactLazyPreload(() => import('./pages/system-configuration/SystemConfiguration'));
SystemConfiguration.preload();
const Statistic = ReactLazyPreload(() => import('./pages/statistic/Statistic'));
Statistic.preload();
const StatisticForm = ReactLazyPreload(() => import('./pages/statistic/StatisticForm'));
StatisticForm.preload();
const ChangePasswords = ReactLazyPreload(() => import('./pages/settings/change-password/ChangePassword'));
ChangePasswords.preload();
const MyAccount = ReactLazyPreload(() => import('./pages/settings/my-account/MyAccount'));
MyAccount.preload();
const Role = ReactLazyPreload(() => import('./pages/role/Role'));
Role.preload();
const RoleForm = ReactLazyPreload(() => import('./pages/role/RoleForm'));
RoleForm.preload();
const BannerForm = ReactLazyPreload(() => import('./pages/banner/BannerForm'));
BannerForm.preload();
const Banner = ReactLazyPreload(() => import('./pages/banner/Banner'));
Banner.preload();
const Languages = ReactLazyPreload(() => import('./pages/languages/Languages'));
Languages.preload();
const AnnouncementForm = ReactLazyPreload(() => import('./pages/announcements/AnnouncementsForm'));
AnnouncementForm.preload();
const Announcement = ReactLazyPreload(() => import('./pages/announcements/Announcements'));
Announcement.preload();
const ContactUs = ReactLazyPreload(() => import('./pages/contact-us/ContactUs'));
ContactUs.preload();
const LoanApplication = ReactLazyPreload(() => import('./pages/loan-application/LoanApplication'));
LoanApplication.preload();
const LoanApplicationForm = ReactLazyPreload(() => import('./pages/loan-application/LoanApplicationForm'));
LoanApplicationForm.preload();
const Guarantor = ReactLazyPreload(() => import('./pages/guarantor/Guarantor'));
Guarantor.preload();
const GuarantorForm = ReactLazyPreload(() => import('./pages/guarantor/GuarantorForm'));
GuarantorForm.preload();
const Applicant = ReactLazyPreload(() => import('./pages/applicant/Applicants'));
Applicant.preload();
const ApplicantForm = ReactLazyPreload(() => import('./pages/applicant/ApplicantForm'));
Applicant.preload();
const LoanRepayment = ReactLazyPreload(() => import('./pages/loan-repayment/LoanRepayment'));
LoanRepayment.preload();
const LoanRepaymentForm = ReactLazyPreload(() => import('./pages/loan-repayment/LoanRepaymentForm'));
LoanRepaymentForm.preload();
const Company = ReactLazyPreload(() => import('./pages/company/Company'));
Company.preload();
const CompanyForm = ReactLazyPreload(() => import('./pages/company/CompanyForm'));
CompanyForm.preload();

export const routes: RouteInfo[] = [
    {
        path: "/login",
        component: React.lazy(() => import('./pages/login/Login'))
    }, {
        path: "/admin/languages",
        component: Languages,
        protected: true
    }, {
        path: "/admin/side-navigation/add-side-navigation",
        component: SideNavigationForm,
        protected: true
    }, {
        path: "/admin/side-navigation/edit-side-navigation/:id",
        component: SideNavigationForm,
        protected: true
    }, {
        path: "/admin/side-navigation",
        component: SideNavigation,
        protected: true
    }, {
        path: "/admin/system-users/edit-system-user/:id",
        component: SystemUserForm,
        protected: true
    }, {
        path: "/admin/system-users/add-system-user",
        component: SystemUserForm,
        protected: true
    }, {
        path: "/admin/system-users",
        component: SystemUser,
        protected: true
    }, {
        path: "/admin/configuration",
        component: SystemConfiguration,
        protected: true
    }, {
        path: "/admin/statistic/add-statistic",
        component: StatisticForm,
        protected: true
    }, {
        path: "/admin/statistic/edit-statistic/:id",
        component: StatisticForm,
        protected: true
    }, {
        path: "/admin/statistic",
        component: Statistic,
        protected: true
    }, {
        path: "/settings/change-password",
        component: ChangePasswords,
        protected: true
    }, {
        path: "/settings/my-account",
        component: MyAccount,
        protected: true
    }, {
        path: "/admin/role/edit-role/:id",
        component: RoleForm,
        protected: true
    }, {
        path: "/admin/role/add-role",
        component: RoleForm,
        protected: true
    }, {
        path: "/admin/roles",
        component: Role,
        protected: true
    }, {
        path: "/customer/guarantor/edit-guarantor/:id",
        component: GuarantorForm,
        protected: true
    }, {
        path: "/customer/guarantor/add-guarantor/:refNo",
        component: GuarantorForm,
        protected: true
    }, {
        path: "/customer/guarantor",
        component: Guarantor,
        protected: true
    }, {
        path: "/marketing/announcements/add-announcement",
        component: AnnouncementForm,
        protected: true
    }, {
        path: "/marketing/announcements/edit-announcement/:id",
        component: AnnouncementForm,
        protected: true
    }, {
        path: "/marketing/announcements",
        component: Announcement,
        protected: true
    }, {
        path: "/marketing/banner/add-banner",
        component: BannerForm,
        protected: true
    }, {
        path: "/marketing/banner/edit-banner/:id",
        component: BannerForm,
        protected: true
    }, {
        path: "/marketing/banners",
        component: Banner,
        protected: true
    }, {
        path: "/enquiries/general",
        component: ContactUs,
        protected: true
    }, {
        path: "/customer/loan-applicant",
        component: Applicant,
        protected: true
    }, {
        path: "/customer/edit-loan-applicant/:id",
        component: ApplicantForm,
        protected: true
    }, {
        path: "/customer/edit-loan-application/:id",
        component: LoanApplicationForm,
        protected: true
    }, {
        path: "/customer/loan-application",
        component: LoanApplication,
        protected: true
    }, {
        path: "/customer/loan-repayment/add-loan-repayment",
        component: LoanRepaymentForm,
        protected: true
    }, {
        path: "/customer/loan-repayment",
        component: LoanRepayment,
        protected: true
    }, {
        path: "/customer/company",
        component: Company,
        protected: true
    }, {
        path: "/customer/edit-company/:id",
        component: CompanyForm,
        protected: true
    }, {
        path: "*",
        component: NoMatch
    }
];